/* eslint-disable react/no-danger */
import React from 'react';
import {
  Button,
  makeStyles,
  Typography,
} from '@material-ui/core';
import { Rating } from '@material-ui/lab';

const useStyles = makeStyles(() => ({
  rating: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: 25,
    marginLeft: 5,
    marginRight: 15,
  },
  button: {
    minHeight: 0,
    minWidth: 0,
    height: '50px',
    width: '50px',
    fontSize: '20px',
    padding: 0,
    // pointerEvents: 'auto',
  },
}));

function ButtonIcon({ value, onClick, formValue }) {
  const classes = useStyles();
  return (
    <Button
      variant="outlined"
      size="small"
      className={[classes.button, 'MuiRating-icon']}
      onClick={() => onClick(value)}
      style={{ backgroundColor: value === formValue ? 'rgba(0, 0, 0, 0.08)' : undefined }}
    >
      {value}
    </Button>
  );
}

function DynamicFormRating({ formik, label, code, hintText, onChange }) {
  const classes = useStyles();
  return (
    <>
      <Typography
        variant="h5"
        gutterBottom
        style={{ color: formik.touched[code] && Boolean(formik.errors[code]) ? 'red' : undefined }}
      >
        {label}
      </Typography>
      <Typography variant="body2" gutterBottom>
        {hintText}
      </Typography>
      <Rating
        name="customized-icons"
        defaultValue={2}
        max={10}
        value={formik.values[code]}
        onChange={(event, newValue) => {
          formik.handleChange({ target: { name: code, value: newValue } });
        }}
        IconContainerComponent={(props) => <ButtonIcon {...props} formValue={formik.values[code]} onClick={(value) => onChange(value)} />}
        className={classes.rating}
      />
      <div style={{ display: 'flex', justifyContent: 'space-between', marginLeft: 5, marginRight: 15 }}>
        <Typography variant="body2" gutterBottom>sehr schlecht</Typography>
        <Typography variant="body2" gutterBottom>sehr gut</Typography>
      </div>
    </>
  );
}

export default DynamicFormRating;
